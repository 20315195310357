import { AxiosInstance } from 'axios'
import { IJsonApiSerializer } from '../JsonApiSerializer'

class AgentService {
  public httpClient: AxiosInstance
  public serializer: IJsonApiSerializer

  constructor(httpClient: AxiosInstance, serializer: IJsonApiSerializer) {
    this.httpClient = httpClient
    this.serializer = serializer
  }

  public getDataFromJson(JsonData) {
    const data = this.serializer.parseResourceData(JsonData, JsonData.data)
    const pagination = { ...JsonData.meta }

    return { data, pagination }
  }

  public getIdentificationsFromJson(data) {
    const identifications = this.serializer.parseResourceData(data, data.data)
    const pagination = { ...data.meta }

    return { identifications, pagination }
  }

  public async createSkills(agent_skill) {
    const { data } = await this.httpClient.post('/agent/api/agent_skills', {
      agent_skill,
    })
    const createdSkills = this.serializer.parseResourceData(data.agent_skill, data.agent_skill.data)

    return { createdSkills, flush: data.flush }
  }
  public async updateSkills(agent_skill) {
    const { data } = await this.httpClient.patch(`/agent/api/agent_skills/${agent_skill.id}`, {
      agent_skill,
    })
    const updatedSkills = this.serializer.parseResourceData(data.agent_skill, data.agent_skill.data)

    return { updatedSkills, flush: data.flush }
  }
  public async deleteSkills(id) {
    return this.httpClient.delete(`/agent/api/agent_skills/${id}`)
  }

  public async createTourSkills(tourSkills) {
    const { data } = await this.httpClient.post('/agent/api/agent_tour_skills', {
      agent_tour_skill: tourSkills,
    })
    const createdTourSkills = this.serializer.parseResourceData(
      data.agent_tour_skill,
      data.agent_tour_skill.data
    )

    return { createdTourSkills, flush: data.flush }
  }

  public async updateTourSkills(tourSkills) {
    const { data } = await this.httpClient.patch(`/agent/api/agent_tour_skills/${tourSkills.id}`, {
      agent_tour_skill: tourSkills,
    })
    const updatedTourSkills = this.serializer.parseResourceData(
      data.agent_tour_skill,
      data.agent_tour_skill.data
    )

    return { updatedTourSkills, flush: data.flush }
  }

  public async deleteTourSkills(id) {
    return this.httpClient.delete(`/agent/api/agent_tour_skills/${id}`)
  }

  public async createRangeTag(range_tag) {
    const { data } = await this.httpClient.post('/agent/api/range_tags', {
      range_tag,
    })
    const createdRangeTags = this.serializer.parseResourceData(data.range_tag, data.range_tag.data)

    return { createdRangeTags, flush: data.flush }
  }
  public async updateRangeTag(range_tag) {
    const { data } = await this.httpClient.patch(`/agent/api/range_tags/${range_tag.id}`, {
      range_tag,
    })
    const updatedRangeTags = this.serializer.parseResourceData(data.range_tag, data.range_tag.data)

    return { updatedRangeTags, flush: data.flush }
  }
  public async deleteRangeTag(id) {
    return this.httpClient.delete(`/agent/api/range_tags/${id}`)
  }

  public async agentSkillAssign(param) {
    const { data } = await this.httpClient.post(`/agent/api/agent_guide_lists/agent_skill_assign`, {
      skill_assign: param,
    })

    return { flush: data.flush }
  }
  public async agentTourSkillAssign(param) {
    const { data } = await this.httpClient.post(
      `/agent/api/agent_guide_lists/agent_tour_skill_assign`,
      {
        skill_assign: param,
      }
    )

    return { flush: data.flush }
  }

  public async GuidePostAssign(param) {
    const { data } = await this.httpClient.post(`/agent/api/agent_guide_edit/guide_post_assign`, {
      guide_user_param: param,
    })

    return { flush: data.flush }
  }

  public async GuideRangeTagsAssign(param) {
    const { data } = await this.httpClient.post(
      `/agent/api/agent_guide_edit/guide_range_tags_assign`,
      {
        guide_user_param: param,
      }
    )

    return { flush: data.flush }
  }

  public async agentSkillUnassign(param) {
    const { data } = await this.httpClient.put(`/agent/api/agent_guide_lists/agent_skill_delete`, {
      skill_assign: param,
    })

    return { flush: data.flush }
  }
  public async agentTourSkillUnassign(param) {
    const { data } = await this.httpClient.put(
      `/agent/api/agent_guide_lists/agent_tour_skill_delete`,
      {
        skill_assign: param,
      }
    )

    return { flush: data.flush }
  }

  public async GuideRangeTagUnassign(param) {
    const { data } = await this.httpClient.put(
      `/agent/api/agent_guide_edit/guide_range_tags_delete`,
      {
        guide_user_param: param,
      }
    )

    return { flush: data.flush }
  }

  public async removeGuideFromChat(reservation_id, guide_id) {
    const { data } = await this.httpClient.get(
      `/api/reservations/${reservation_id}/remove_from_chat/${guide_id}`
    )

    return { data }
  }

  public async guideUserUpdate(param) {
    const { data } = await this.httpClient.post(`/agent/api/agent_guide_edit/guide_user_update`, {
      guide_user_param: param,
    })
    return { flush: data.flush }
  }

  // 打診
  public async createSoundOutTemplate(sound_out_template) {
    const { data } = await this.httpClient.post(`/agent/api/sound_out_templates`, {
      sound_out_template,
    })
    const createdTemplate = this.serializer.parseResourceData(
      data.sound_out_template,
      data.sound_out_template.data
    )

    return { createdTemplate, flush: data.flush }
  }

  public async updateSoundOutTemplate(id, sound_out_template) {
    const { data } = await this.httpClient.put(`/agent/api/sound_out_templates/${id}`, {
      sound_out_template,
    })
    const updatedSoundOutTemplate = this.serializer.parseResourceData(
      data.sound_out_template,
      data.sound_out_template.data
    )

    return { updatedSoundOutTemplate, flush: data.flush }
  }

  public async deleteSoundOutTemplate(id) {
    const { data } = await this.httpClient.delete(`/agent/api/sound_out_templates/${id}`)

    return { flush: data.flush }
  }

  // 正式依頼
  public async createFormalRequestTemplate(formal_request_template) {
    const { data } = await this.httpClient.post(`/agent/api/formal_request_templates`, {
      formal_request_template,
    })
    const createdTemplate = this.serializer.parseResourceData(
      data.formal_request_template,
      data.formal_request_template.data
    )

    return { createdTemplate, flush: data.flush }
  }

  public async updateFormalRequestTemplate(id, formal_request_template) {
    const { data } = await this.httpClient.put(`/agent/api/formal_request_templates/${id}`, {
      formal_request_template,
    })
    const updatedFormalRequestTemplate = this.serializer.parseResourceData(
      data.formal_request_template,
      data.formal_request_template.data
    )

    return { updatedFormalRequestTemplate, flush: data.flush }
  }

  public async deleteFormalRequestTemplate(id) {
    const { data } = await this.httpClient.delete(`/agent/api/formal_request_templates/${id}`)

    return { flush: data.flush }
  }

  // サンクスメール
  public async createMailTemplate(mail_template) {
    const { data } = await this.httpClient.post(`/agent/api/mail_templates`, { mail_template })
    const createdMailTemplate = this.serializer.parseResourceData(
      data.mail_template,
      data.mail_template.data
    )

    return { createdMailTemplate, flush: data.flush }
  }

  public async updateMailTemplate(id, mail_template) {
    const { data } = await this.httpClient.put(`/agent/api/mail_templates/${id}`, { mail_template })
    const updatedMailTemplate = this.serializer.parseResourceData(
      data.mail_template,
      data.mail_template.data
    )

    return { updatedMailTemplate, flush: data.flush }
  }

  public async deleteMailTemplate(id) {
    const { data } = await this.httpClient.delete(`/agent/api/mail_templates/${id}`)

    return { flush: data.flush }
  }

  public async updateReservation(reservation: any, isReservation: boolean) {
    const { id } = reservation
    const param = isReservation
      ? { reservation }
      : {
          direct_reservation: { ...reservation },
        }

    const { data } = await this.httpClient.patch(`/agent/api/reservations/${id}`, param)

    const updatedReservation = this.serializer.parseResourceData(
      data.reservation,
      data.reservation.data
    )

    return { updatedReservation, flush: data.flush }
  }

  public async getContracts(params) {
    const { data } = await this.httpClient.get(`/agent/api/contracts/`, {
      params,
    })
    return { contractsData: data.contracts }
  }

  public async getTemplates() {
    const templates = await this.httpClient.get(`/agent/api/contracts/templates`, {})
    return templates.data.templates
  }

  public async createContract(params) {
    const { data } = await this.httpClient.post(`/agent/api/contracts`, {
      params,
    })
    return { data, flush: data.flush }
  }

  public async updateAgentRanks(rank_ids) {
    const { data } = await this.httpClient.patch(`/agent/api/agent_ranks`, { rank_ids })
    return { agentRanks: data.agent_ranks, flush: data.flush }
  }

  public async updateAgentGuide(id, formData) {
    const { data } = await this.httpClient.patch(`/agent/api/agent_guides/${id}`, formData)
    return { agentRanks: data.agent_ranks, flush: data.flush }
  }

  public async updatePayment(payment) {
    const { data } = await this.httpClient.patch(`/agent/api/payments/${payment.id}`, {
      payment,
    })
    const updatedPayment = this.serializer.parseResourceData(data.payment, data.payment.data)

    return { updatedPayment, flush: data.flush }
  }

  public async createPaymentItem(tourGroup) {
    const { data } = await this.httpClient.post(`/agent/api/payment_items`, {
      payable_id: tourGroup.id,
      payable_type: 'TourGroup',
    })
    const createdPaymentItem = this.serializer.parseResourceData(
      data.payment_item,
      data.payment_item.data
    )

    return { createdPaymentItem, flush: data.flush }
  }

  public async updatePaymentItem(payment_item) {
    const { data } = await this.httpClient.patch(`/agent/api/payment_items/${payment_item.id}`, {
      payment_item,
    })
    const updatedPaymentItem = this.serializer.parseResourceData(
      data.payment_item,
      data.payment_item.data
    )

    return { updatedPaymentItem, flush: data.flush }
  }

  public async sendPaymentNotification(payment) {
    const { data } = await this.httpClient.post(
      `/agent/api/payments/${payment.id}/send_notification`
    )
    return { updatedPayment: data.payment, flush: data.flush }
  }

  public async sendPaymentConfirmation(payment_ids) {
    const { data } = await this.httpClient.patch(`/agent/api/payments/send_confirmation`, {
      payment_ids,
    })
    return { payments: data.payments, flush: data.flush }
  }

  public async downloadSpreadSheetPayments(guides, month, share, issue_date) {
    const { data } = await this.httpClient.post(`/power_user/api/payments/download`, {
      guides,
      month,
      share,
      issue_date,
    })
    return { flush: data.flush }
  }

  public async postFormalRequests(formData) {
    const { data, status } = await this.httpClient.post(`/agent/api/formal_requests`, formData)

    return { flush: data.flush, status }
  }

  public async postSoundOuts(formData) {
    const { data, status } = await this.httpClient.post(`/agent/api/sound_outs`, formData)

    return { flush: data.flush, status }
  }

  public async tourGuideCancel(id, formal_request) {
    const { data } = await this.httpClient.put(`/agent/api/formal_requests/${id}/cancel`, {
      formal_request,
    })
    return {
      tourGuide: data.tour_guide,
      tourGuideMessage: data.tour_guide_message,
      flush: data.flush,
    }
  }

  public async updateTour(tour_id, params) {
    const { data } = await this.httpClient.patch(`/agent/api/tours/${tour_id}`, params)
    const tour = this.serializer.parseResourceData(data.tour, data.tour.data)

    return { tour, flush: data.flush }
  }

  public async updateTourGroup(tour_group_id, params) {
    const { data } = await this.httpClient.patch(`/agent/api/tour_groups/${tour_group_id}`, params)
    const tour_group = this.serializer.parseResourceData(data.tour_group, data.tour_group.data)

    return { tour_group, flush: data.flush }
  }

  public async updateTourReservation(tour_reservation_id: any, params: any) {
    const { data } = await this.httpClient.patch(
      `/agent/api/tour_reservations/${tour_reservation_id}`,
      params
    )
    const tour_reservation = this.serializer.parseResourceData(
      data.tour_reservation,
      data.tour_reservation.data
    )

    return { tour_reservation, flush: data.flush }
  }

  public async createCalendarMemo(params) {
    const { data } = await this.httpClient.post(`/agent/api/calendar_memos`, {
      calendar_memo: params,
    })

    const updatedCalendarMemo = this.serializer.parseResourceData(
      data.calendar_memo,
      data.calendar_memo.data
    )

    return { updatedCalendarMemo, flush: data.flush }
  }

  public async updateCalendarMemo(calendar_memo_id, params) {
    const { data } = await this.httpClient.patch(`/agent/api/calendar_memos/${calendar_memo_id}`, {
      calendar_memo: params,
    })

    const updatedCalendarMemo = this.serializer.parseResourceData(
      data.calendar_memo,
      data.calendar_memo.data
    )

    return { updatedCalendarMemo, flush: data.flush }
  }

  public async deleteCalendarMemo(calendar_memo_id) {
    const { data } = await this.httpClient.delete(`/agent/api/calendar_memos/${calendar_memo_id}`)

    const updatedCalendarMemo = this.serializer.parseResourceData(
      data.calendar_memo,
      data.calendar_memo.data
    )

    return { updatedCalendarMemo, flush: data.flush }
  }

  public async setCalendarMemo(calendar_memo_id, params) {
    if (!calendar_memo_id) {
      return this.createCalendarMemo(params)
    }
    if (params.memo === '' && params.internal_memo === '') {
      return this.deleteCalendarMemo(calendar_memo_id)
    }

    return this.updateCalendarMemo(calendar_memo_id, params)
  }

  public async updateGuide(guide_id, params) {
    const { data } = await this.httpClient.patch(`/agent/api/guides/${guide_id}`, params)
    const updatedUser = this.serializer.parseResourceData(data.guide, data.guide.data)

    return { user: updatedUser, flush: data.flush }
  }

  public async getGuides(params, tour_id, page) {
    params.tour_id = tour_id
    const { data } = await this.httpClient.get(`/agent/api/guides?page=${page}`, { params })
    const guides = this.serializer.parseResourceData(data.guides.users, data.guides.users.data)
    const pagination = data.guides.users.meta

    return { guides, pagination }
  }

  public async importGuides() {
    const { data } = await this.httpClient.post(`/agent/api/guides/import`)
    const { flush } = data
    return { flush }
  }

  public async getAgentSkills() {
    const { data } = await this.httpClient.get(`/agent/api/agent_skills`)
    const agent_skills = this.serializer.parseResourceData(
      data.agent_skills,
      data.agent_skills.data
    )

    return { agent_skills }
  }

  public async getAgentTourSkills() {
    const { data } = await this.httpClient.get(`/agent/api/agent_tour_skills`)
    const agent_tour_skills = this.serializer.parseResourceData(
      data.agent_tour_skills,
      data.agent_tour_skills.data
    )

    return { agent_tour_skills }
  }

  public async updateRelatedPosts(post_id, related_post_ids) {
    const { data } = await this.httpClient.patch(`/agent/api/posts/${post_id}/related_posts`, {
      related_post_ids,
    })
    return { flush: data.flush }
  }
}

export default AgentService
